/* eslint-disable eqeqeq */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// import Attachment from '../pages/dashboard/Attachment';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password/:token', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
          ],
        },
        { path: 'hooks', element: <HookPage /> },
        {
          path: 'contacts',
          children: [
            { element: <Navigate to="/dashboard/contacts/list" replace />, index: true },
            { path: 'list', element: <Contacts /> },
            { path: ':id', element: <ContactView /> },
            { path: 'new', element: <EcommerceProductCreate /> },
          ],
        },
        {
          path: 'autoreply',
          children: [
            { element: <Navigate to="/dashboard/autoreply/list" replace />, index: true },
            { path: 'list', element: <AutoReply /> },
            { path: 'add/:id', element: <AutoNewEditForm /> },
            { path: ':id/edit', element: <EditAutoReply /> },
            { path: ':id/view', element: <AutoReplyCard /> },
          ],
        },
        {
          path: 'welcome',
          children: [
            { element: <Navigate to="/dashboard/welcome/list" replace />, index: true },
            { path: 'list', element: <Welcome /> },
            { path: 'add/:id', element: <AutoNewEditForm /> },
            { path: ':id/edit', element: <EditAutoReply /> },
            { path: ':id/view', element: <AutoReplyCard /> },
          ],
        },
        {
          path: 'api',
          children: [
            {
              element: <Navigate to="/dashboard/api/list" replace />,
              index: true,
            },
            { path: 'list', element: <API /> },
          ],
        },
        {
          path: 'admin',
          children: [
            {
              element: <Navigate to="/dashboard/admin/users" replace />,
              index: true,
            },
            { path: 'users', element: <Admin /> },
            { path: ':id', element: <AdminChannels /> },
          ],
        },
        {
          path: 'dashboard',
          children: [
            { element: <Navigate to="/dashboard/dashboard/users" replace /> },
            { path: 'users', element: <UsersRes /> },
            { path: 'queries', element: <QueryRes /> },
            { path: ':id/channels', element: <ResellerChannels /> },
            { path: ':id/users', element: <UsersRes /> },
            { path: 'channels', element: <ResellerSalingChannels /> },
          ],
        },
        {
          path: 'businesstools',
          children: [
            {
              element: <Navigate to="/dashboard/businesstools/catalogue" replace />,
              index: true,
            },
            // { path: 'catalogue', element: <Catalogue /> },
            // { path: 'orders', element: <Orders /> },
            { path: 'orders/:id/detail', element: <OrderDetail /> },
            { path: 'orders/:id/edit', element: <OrderEdit isEdit /> },
            // { path: 'orders/:channelId/:id/create', element: <OrderEdit isCreate /> },
            { path: 'invoice', element: <Invoice /> },
            { path: 'invoice/create', element: <InvoiceNewEditForm /> },
          ],
        },
        {
          path: 'button',
          children: [
            { element: <Navigate to="/dashboard/button/list" replace />, index: true },
            { path: 'list', element: <ButtonMsg /> },
            { path: 'new', element: <ButtonNewEditForm /> },
            { path: 'edit/:id', element: <EditButtonMsg /> },
          ],
        },
        {
          path: 'template',
          children: [
            { element: <Navigate to="/dashboard/template/list" replace />, index: true },
            { path: 'list', element: <TemplateListPage /> },
          ],
        },
        { path: 'unsubscribers', element: <UnsubscribePage /> },
        { path: 'leads', element: <LeadPage /> },
        {
          path: 'channels',
          children: [
            { element: <Navigate to="/dashboard/channels/list" replace />, index: true },
            { path: 'list', element: <Channels /> },
            { path: 'new', element: <CampagainCreate /> },
          ],
        },
        {
          path: 'campaign',
          children: [
            { element: <Navigate to="/dashboard/campaign/list" replace />, index: true },
            { path: 'list', element: <Campaign /> },
            { path: 'new', element: <CampagainCreate /> },
            { path: ':id/copy', element: <CampaignCopy /> },
            { path: ':id/edit', element: <CampaignEdit /> },
          ],
        },
        {
          path: 'integration',

          children: [
            { element: <Navigate to="/dashboard/integration/spead-sheet" replace />, index: true },
            { path: 'spead-sheet', element: <SpreadSheet /> },
            { path: 'zoho', element: <Zoho /> },
            { path: 'custom', element: <PabllyEventListPage /> },
            { path: 'webhook', element: <WebhookListPage /> },
          ],
        },
        {
          path: 'attachment',
          children: [
            { element: <Navigate to="/dashboard/attachment/list" replace />, index: true },
            { path: 'list', element: <Attachment /> },
            // { path: 'post/:title', element: <attachmentPost /> },
            // { path: 'new', element: <attachmentNewPost /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        { path: 'green-tick', element: <Official /> },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat />, index: true },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> },
        //   ],
        // },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// ECOMMERCE
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));

// USER

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// invoice
const Invoice = Loadable(lazy(() => import('../pages/dashboard/Invoice')));
const Channels = Loadable(lazy(() => import('../pages/dashboard/Channels')));
const Contacts = Loadable(lazy(() => import('../pages/dashboard/Contacts')));
const Campaign = Loadable(lazy(() => import('../pages/dashboard/Campaign')));
const CampaignCopy = Loadable(lazy(() => import('../pages/dashboard/CampagainCopy')));
const CampaignEdit = Loadable(lazy(() => import('../pages/dashboard/CampagainEdit')));

const Zoho = Loadable(lazy(() => import('../pages/dashboard/Zoho')));

const CampagainCreate = Loadable(lazy(() => import('../pages/dashboard/CampagainCreate')));
const Attachment = Loadable(lazy(() => import('../pages/dashboard/Attachment')));
const ContactView = Loadable(lazy(() => import('../sections/@dashboard/Contact/ContactView')));
const SpreadSheet = Loadable(lazy(() => import('../pages/dashboard/SpreadSheet')));
const UnsubscribePage = Loadable(lazy(() => import('../pages/dashboard/UnsubscribePage')));
const LeadPage = Loadable(lazy(() => import('../pages/dashboard/LeadPage')));
const HookPage = Loadable(lazy(() => import('../pages/dashboard/HookPage')));
const TemplateListPage = Loadable(lazy(() => import('../pages/dashboard/template/TemplateListPage')));

const ButtonMsg = Loadable(lazy(() => import('../pages/dashboard/ButtonMsg')));
const EditButtonMsg = Loadable(lazy(() => import('../pages/dashboard/EditButtonMsg')));
const ButtonNewEditForm = Loadable(lazy(() => import('../sections/@dashboard/ButtonNewForm/index')));
const AutoNewEditForm = Loadable(lazy(() => import('../sections/@dashboard/AutoReply')));
const AutoReply = Loadable(lazy(() => import('../pages/dashboard/AutoReply')));
const Welcome = Loadable(lazy(() => import('../pages/dashboard/Welcome')));

const AutoReplyCard = Loadable(lazy(() => import('../pages/dashboard/AutoReplyCard')));
const EditAutoReply = Loadable(lazy(() => import('../pages/dashboard/EditAutoReply')));

const API = Loadable(lazy(() => import('../pages/dashboard/api')));
const Admin = Loadable(lazy(() => import('../pages/dashboard/admin')));
const AdminChannels = Loadable(lazy(() => import('../sections/@dashboard/Admin/AdminChannels')));
const OrderDetail = Loadable(lazy(() => import('../pages/dashboard/OrderView')));
const OrderEdit = Loadable(lazy(() => import('../sections/@dashboard/OrderView/edit')));
const InvoiceNewEditForm = Loadable(lazy(() => import('../sections/@dashboard/invoice/new-edit-form')));
const Official = Loadable(lazy(() => import('../pages/dashboard/Official')));

const PabllyEventListPage = Loadable(lazy(() => import('../pages/dashboard/PabllyEventListPage')));

const WebhookListPage = Loadable(lazy(() => import('../pages/dashboard/WebhookListPage')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));
// RESEALER
const QueryRes = Loadable(lazy(() => import('../pages/dashboard/QueryRes')));
const UsersRes = Loadable(lazy(() => import('../pages/dashboard/UsersRes')));
const ResellerChannels = Loadable(lazy(() => import('../pages/dashboard/ResellerChannels')));
const ResellerSalingChannels = Loadable(lazy(() => import('../pages/dashboard/ResellerSalingChannels')));

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
